var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "mb aliceblue",
      attrs: { align: "middle", justify: "center", type: "flex" }
    },
    [
      _c("a-col", { staticStyle: { "margin-bottom": "20px" } }, [
        _c("div", [
          _c("span", {
            staticClass: "ql-editor",
            domProps: { innerHTML: _vm._s(_vm.mot_de_president) }
          })
        ])
      ]),
      _c("VueEditor", { staticStyle: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }