<template>
  <a-row align="middle" class="mb aliceblue" justify="center" type="flex">
    <a-col style="margin-bottom: 20px">
      <div>
        <span class="ql-editor" v-html="mot_de_president"> </span>
      </div>
    </a-col>
    <!-- 
      @TODO remove me !
      This is here just in order to load the necessary CSS files
      There should be another way to load them but I didn't find it !
    -->
    <VueEditor style="display:none"></VueEditor>
  </a-row>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "Mot de president",
  components: {
    VueEditor
  },
  props: { mot_de_president: { type: String } },
};
</script>
<style>
.members {
  padding-top: 2%;
}

.title {
  margin-top: 10px;
}

.img {
  max-width: 226px;
  display: block;
  width: auto;
}

.grade {
  font-size: larger;
}

.txt-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.my-blue {
  font-family: Sora, ubuntu, monospace;
  font-weight: 600;
  color: rgb(31 54 92);
}
</style>